footer {
  background-color: #000;
  padding: 36px 0px;
}
.credits,
.credits:hover {
  color: rgb(255, 255, 255);
  font-family: "Elevance-Regular";
  font-size: 14px;
  text-decoration: none;
}
.credits a {
  text-decoration: underline;
}
.footer-links {
  float: right;
  margin-bottom: 0px;
}
.footer-links .credits {
  color: rgb(255, 255, 255);
  font-family: "Elevance-Bold";
  font-size: 14px;
}
.footer-links li {
  display: inline-block;
  margin-left: 20px;
}
.btn-wcag {
  color: rgb(255, 255, 255) !important;
  font-family: "Elevance-Regular";
  font-size: 13px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
  border: 2px solid rgba(255, 255, 255, 0.1) !important;
}
.mobile-footer {
  display: none !important;
}
.mobile-footer ul li {
  list-style: none;
  margin-bottom: 15px;
}
.mobile-footer .termsofuse,
.mobile-footer .protectingprivacy {
  color: rgb(255, 255, 255);
  font-family: "Elevance-Bold";
  font-size: 14px;
}

@media only screen and (max-width: 767px) and (min-width: 360px) {
  .desktop-footer {
    display: none !important;
  }
  .mobile-footer {
    display: block !important;
  }
}
