/* @font-face {
  font-family: 'Lato-Regular';
  src: url('./fonts/Lato-Regular.woff2') format('woff2'),
      url('./fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('./fonts/Lato-Bold.woff2') format('woff2'),
      url('./fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato-SemiBold';
  src: url('./fonts/Lato-Semibold.woff2') format('woff2'),
      url('./fonts/Lato-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bitter-SemiBold';
  src: url('./fonts/Bitter-SemiBold.woff2') format('woff2'),
      url('./fonts/Bitter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bitter-Medium';
  src: url('./fonts/Bitter-Medium.woff2') format('woff2'),
      url('./fonts/Bitter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Elevance-Regular';
  src: url('./fonts/ElevanceSans-Regular.woff2') format('woff2'),
      url('./fonts/ElevanceSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Elevance-Bold';
  src: url('./fonts/ElevanceSans-Bold.woff2') format('woff2'),
      url('./fonts/ElevanceSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Elevance-SemiBold';
  src: url('./fonts/ElevanceSans-Semibold.woff2') format('woff2'),
      url('./fonts/ElevanceSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Elevance-Medium';
  src: url('./fonts/ElevanceSans-Regular.woff2') format('woff2'),
      url('./fonts/ElevanceSans-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



body{
  font-family: 'Elevance-Medium',serif !important;
}
