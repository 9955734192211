.tooltip > .tooltip-inner {
  width: 300px;
  font-family: "Elevance-Medium", serif;
}
.tooltip-inner {
  background-color: #fff !important;
  color: #000000 !important;
  border: 1px solid #286ce2;
  min-width: 355px !important;
}
.tooltip-inner ul {
  padding: 0 !important;
  list-style-type: none;
  margin: 0;
  text-align: left;
}

.tooltip-inner li {
  padding-bottom: 10px !important;
  border-bottom: 1px solid;
}
.tooltip-inner li:last-child {
  border-bottom: none;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #fff !important;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #fff;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #fff;
}
