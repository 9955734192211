
.mapboxgl-map {
  height: 88vh !important;
  position: relative;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.opened-nav {
  width: 30% !important;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(90%);
    transform: translateY(90%);
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(90%);
    transform: translateY(90%);
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.fwc-anim-fadeIn-up {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.fwc-anim-fadeIn-up {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.search-input input {
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.MapPopup,
.ZipPopUp {
  color: #333333 !important;
}
.ZipPopUp {
  width: 313px;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 10%);
  height: auto;
  position: absolute;
  z-index: 2;
  left: 749px;
  padding: 20px;
  top: 77px;
}
.MapPopup .breadcrumb > li + li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  font-weight: 600;
  font-size: 20px;
  padding-top: 0;
}
.mobileMap .breadcrumb > li + li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  font-weight: 600;
  font-size: 16px;
  padding-top: 0;
}
.MapPopup .breadcrumb > li a,
.MapPopup .breadcrumb > li {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Elevance-SemiBold';
}
.MapPopup .modal-header {
  padding: 0.9rem 2em !important;
  border-bottom: 0 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 10%);
  /* height: 25px !important; */
}
.MapPopup .modal-content {
  background-color: transparent !important;
  border: 0;
}
/* @media (min-width: 576px) {
  .mapContainer .modal-dialog {
    max-width: 650px !important;
    margin: 1.75rem auto;
  }
} */
.MapPopup .breadcrumb {
  margin-bottom: 0 !important;
  background: none !important;
  padding: 0 !important;
  line-height: 15px;
}

.MapPopup .modal-body {
  border-radius: 16px;
  background-color: white;
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  padding-bottom: 5px;
}
.MapPopup .modal-footer {
  border-radius: 16px;
  background-color: white;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-link.active:after {
  content: "";
  position: relative;
  bottom: -57px;
  left: -23%;
  border: 15px solid transparent;
  border-top-color: white;
  z-index: 4;
}
.MapPopup .nav-link.active {
  font-weight: bold;
  font-family: 'Elevance-SemiBold';
}
.MapPopup .nav-tabs,
.MapPopup .nav-link.active {
  border: 0 !important;
}
.MapPopup .LabelTab {
  height: 80px;
  background: #286ce2;
  margin: 0px -2px 0px -1px;
  padding: 28px 10%;
  color: white;
  font-family: "Elevance-SemiBold", serif;
  text-transform: capitalize;
}
.MapPopup .tab-content {
  margin-top: -16px;
}
.MapPopup .nav-item {
  cursor: pointer !important;
}
.MapPopup .nav-link {
  color: #333333 !important;
  font-size: 18px;
}
.MapPopup .modal-content {
  border-radius: 0 !important;
  border: 0;
}
.MapPopup {
  left: 70px;
  position: absolute;
  z-index: 2;
  width: 650px;
  top: 10px;
}
.ZipPopUp .modal-dialog {
  float: none !important;
}
.ZipPopUp .modal-header {
  border-radius: 16px;
  background: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 10%); */
  border-bottom: 0;
  padding-bottom: 0;
}
.ZipPopUp .modal-content {
  background-color: transparent !important;
  border: 0;
  top: 55px;
}
.ZipPopUp .modal-body {
  border-radius: 16px;
  background: white;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /* box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 10%); */
  padding-top: 0;
}
.ZipPopUp .search-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9999;
}
.ZipPopUp .ZipSearch {
  border-radius: 8px;
  color: #286ce2;
  border-right: 0;
}
.ZipPopUp.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.ZipPopUp.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
/*img::after {*/
/*  content: attr(data-tooltip);*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 14px;*/
/*  width: 100px;*/
/*  background-color: #000;*/
/*  color: #fff;*/
/*  font-family: Arial, sans-serif;*/
/*  font-size: 14px;*/
/*  padding: 5px;*/
/*  opacity: 0;*/
/*  pointer-events: none;*/
/*  transition: opacity 0.3s ease-in-out;*/
/*  will-change: opacity;*/
/*}*/

#search-button {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  cursor: pointer;
  border-left: 0;
}
.search-input input {
  border-radius: 8px;
  font-size: 14px;
  border-right: none;
  width: 210px !important;
  border-end-end-radius: 0;
  border-start-end-radius: 0;
}

/* / The checkbox-container / */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #0c0916;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* / Hide the browser's default checkbox / */
.checkbox-container .input-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* / Create a custom checkbox / */
.checkbox-container .checkmarked {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background: #ffffff;
  border: 1px solid #d6dce4;
  box-sizing: border-box;
  border-radius: 2px;
}
.form-control:focus {
  background-color: #fff;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
  /* border-radius: 0 !important; */
}
/* / On mouse-over, add a grey background color / */
.checkbox-container:hover .input-checkbox ~ .checkmarked {
  background: #ffffff;
  border: 1px solid #d6dce4;
  box-sizing: border-box;
  border-radius: 2px;
}

/* / When the checkbox is checked, add a blue background / */
.checkbox-container .input-checkbox:checked ~ .checkmarked {
  background-color: #4647c6;
}

/* / Create the checkmarked/indicator (hidden when not checked) / */
.checkbox-container .checkmarked:after {
  content: "";
  position: absolute;
  display: none;
}

/* / Show the checkmarked when checked / */
.checkbox-container .input-checkbox:checked ~ .checkmarked:after {
  display: block;
}

/* / Style the checkmarked/indicator / */
.checkbox-container .checkmarked:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#lowernavbar label {
  font-family: "Elevance-Regular";
  font-size: 14px;
  color: rgb(51, 51, 51);
}
#lowernavbar li {
  margin-left: 25px;
}
#lowernavbar label input {
  margin-right: 8px;
  margin-top: 5px;
}
.custom-filters {
  font-family: "Elevance-Bold";
  font-size: 14px;
  color: rgb(40, 108, 226) !important;
  padding-top: 11px !important;
}
.foodinsecurtybypercentage {
  font-size: 12px;
  font-family: "Elevance-Regular";
  color: rgb(51, 51, 51);
}
.range {
  font-size: 12px;
  font-family: "Elevance-Regular";
  color: rgb(51, 51, 51);
  text-align: center;
  margin-bottom: 5px;
}
.green-one {
  background: #EBE4FF;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.green-two {
  background: #794CFF;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.green-three {
  background: #5009B5;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.green-four {
  background: #2B1B49;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.green-five {
  background: #110A1D;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
#foodinsecurtypercentageradio {
  top: 35%;
  transform: translateY(-50%);
  z-index: 999;
}
.vulnerability-one {
  background: #EBE4FF;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.vulnerability-two {
  background: #794CFF;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.vulnerability-three {
  background: #5009B5;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}

.vulnerability-four {
  background: #2B1B49;
  border-radius: 0px;
  height: 8px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}
.switch {
  position: relative;
  display: inline-block;
  width: 57px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.toggle-switch {
  color: rgb(51, 51, 51);
  font-family: "Elevance-SemiBold";
  font-size: 14px;
  position: relative;
  /*top: 20%;*/
  transform: translateY(-50%);
  /* left: 11%; */
  width: 100%;
}
.desktop-lowernav {
  display: block !important;
}
.mobile-lowernav {
  display: none !important;
}
.mobile-locationsearch {
  background: rgb(255, 255, 255);
  border-radius: 8px !important;
  border: 1px solid rgb(234, 234, 234);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  height: 40px;
  color: rgb(51, 51, 51);
  font-family: "Elevance-Regular";
  font-size: 14px !important;
}
.btn-filter {
  border-radius: 8px !important;
  border: 2px solid rgb(240, 245, 255) !important;
  height: 40px;
  color: rgb(40, 108, 226) !important;
  font-family: 'Elevance-Bold';
  font-size: 16px;
}
.sidenav {
  height: 100%;
  width: 0;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  z-index: 9;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 5px;
}

.sidenav a {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}
.marker-blue {
  color: #007bff;
}
.state_county-name {
  color: rgb(51, 51, 51);
  font-family: 'Elevance-Bold';
  font-size: 18px;
}
.data-list {
  padding-left: 0px;
  list-style: none;
}
.data-label {
  color: rgb(51, 51, 51);
  font-family: 'Elevance-Bold';
  font-size: 12px;
  margin-bottom: 0px;
}
.state_county_data-section hr {
  background: rgb(204, 204, 204);
}
.data-label i {
  color: #175bd1;
}
.data-value {
  color: rgb(51, 51, 51);
  font-family: 'Elevance-Regular';
  font-size: 14px;
}

.sidenav .closebtn {
  background: rgb(247, 247, 247);
  border-radius: 8px;
  height: 46px;
  width: 46px;
  padding: 0px;
  text-align: center;
  position: relative;
  margin-left: 15px;
  margin-top: 10px;
}
.sidenav .closebtn i {
  color: #6293e7;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}
.sidenav-heading {
  color: rgb(51, 51, 51);
  font-family: "Elevance-SemiBold";
  font-size: 28px;
}
.sidenav-wrapper {
  padding: 20px 30px;
  z-index: 1;
}
.sidenav-wrapper .sidebar-foodinsecurity {
  margin-top: 20px;
}
.sidenav-wrapper .sidebar-foodinsecurity,
.sidenav-wrapper .sidebar-socialvulnerability {
  margin-bottom: 10px;
}
.sidenav-wrapper .sidebar-foodinsecurity label,
.sidenav-wrapper .sidebar-socialvulnerability label {
  color: rgb(51, 51, 51);
  font-family: 'Elevance-Regular';
  font-size: 14px;
}
.sidenav-wrapper .sidebar-foodinsecurity i,
.sidenav-wrapper .sidebar-socialvulnerability i {
  color: #2b6ee2;
  cursor: pointer;
  margin-left: 5px;
}
.sidenav-wrapper .sidebar-foodinsecurity input,
.sidenav-wrapper .sidebar-socialvulnerability input {
  margin-top: 7px;
}
.toggle-switch-mobile {
  color: rgb(51, 51, 51);
  font-family: "Elevance-Bold";
  font-size: 14px;
  margin-top: 20px;
}
.toggle-switch-mobile .switch {
  width: 40px;
  height: 24px;
}
.toggle-switch-mobile .slider:before {
  height: 20px;
  width: 20px;
}
.toggle-switch-mobile input:checked + .slider:before {
  transform: translateX(15px);
}
.toggle-switch-mobile i {
  color: #6293e7;
  margin-left: 5px;
}
.sidenav-subheading {
  color: rgb(51, 51, 51);
  font-family: "Elevance-Medium";
  font-size: 24px;
  margin-top: 20px;
}
.insecure-people {
  color: rgb(51, 51, 51);
  font-family: "Elevance-Medium";
  font-size: 20px;
  margin-top: 20px;
}
.socioeconomic-people {
  color: rgb(51, 51, 51);
  font-family: "Elevance-MediumF";
  font-size: 20px;
  margin-top: 60px;
}
.irs-min,
.irs-max {
  display: none !important;
}
.irs-from,
.irs-to,
.irs-single {
  top: 60px !important;
  background: #fff !important;
  color: #000 !important;
  font-family: 'Elevance-Regular';
  font-size: 14px;
}
.irs-line {
  background: rgb(247, 247, 247) !important;
  border: none !important;
}
.irs--big .irs-bar {
  background: #286ce2 !important;
  border: #286ce2 !important;
}
.irs-handle {
  background: #fff !important;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25), 0px 0px 2px 0px rgba(0, 0, 0, 0.17) !important;
  height: 24px !important;
  width: 24px !important;
}
.food-insecurtity,
.median-income {
  color: rgb(51, 51, 51);
  font-family: 'Elevance-Bold';
  font-size: 12px;
}
.food-insecurtity i,
.median-income i,
.social-vulnerability i {
  color: #286ce2;
}
.social-vulnerability {
  color: rgb(51, 51, 51);
  font-family: "Elevance-Bold";
  font-size: 12px;
  /* margin-top: 60px; */
}
.sidenav-wrapper hr {
  border-top: 2px solid rgb(238, 238, 238);
}
.btn-clear {
  border-radius: 8px !important;
  border: 2px solid rgb(240, 245, 255) !important;
  color: rgb(40, 108, 226) !important;
  font-family: "Elevance-Bold";
  font-size: 16px !important;
  width: 100%;
}
.btn-apply {
  color: rgb(255, 255, 255) !important;
  font-family: "Elevance-Bold";
  font-size: 16px !important;
  background: #175bd1 !important;
  width: 100%;
}
.state_county_data-section,
.mobile_race {
  display: none;
}
.mobile-controls-wrapper {
  position: relative;
  /* z-index: 0; */
}
.mobile-controls {
  display: none;
  position: absolute;
  width: 86px;
  height: 86px;
  bottom: 3%;
  left: 4%;
}
.mobile-up-control {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}
.mobile-right-control {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.mobile-bottom-control {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
}
.mobile-left-control {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 0px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1590px) and (min-width: 1130px) {
  .search-input input {
    border-radius: 8px;
    font-size: 11px;
    border-right: none;
    width: 130px !important;
    padding: 5px !important;
  }
  #lowernavbar label, .toggle-switch, .custom-filters {
    font-size: 12px;
  }
  .input-group-append {
    margin-left: -6px !important;
  }
  #lowernavbar label {
    font-family: "Elevance-Regular";
    font-size: 12px;
    display: block;
  }

}
@media only screen and (max-width: 767px) and (min-width: 360px) {
  .mapboxgl-map {
    height: 85vh !important;
  }
  .social-vulnerability-legends {
    display: none !important;
  }
  .search-input input {
    width: 150px !important;
  }
  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 20px 10px 10px !important;
  }
  .opened-nav {
    width: 90% !important;
  }
  .mobile-controls {
    display: block !important;
  }
  .toggle-switch {
    display: none !important;
  }
  #foodinsecurtypercentageradio {
    display: none !important;
  }
  .desktop-lowernav {
    display: none !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url("../assets/images/zoom-in.svg") !important;
    margin-right: 0 !important;
  }
  .mapboxgl-ctrl-group button {
    margin-right: 10px !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url("../assets/images/zoom-out.svg") !important;
    margin-left: 0 !important;
  }
  .mapboxgl-ctrl button:not(:disabled):hover {
    background-color: transparent !important;
  }
  .mobile-lowernav {
    display: block !important;
  }
  .state_county_data-section,
  .mobile_race {
    display: block;
  }
  .mapboxgl-ctrl-bottom-left {
    right: 5% !important;
    left: auto !important;
  }
  .mapboxgl-ctrl-group {
    display: flex;
    background: transparent !important;
    box-shadow: none !important;
  }
  .mapboxgl-ctrl-group button + button {
    border-top: none !important;
  }
  .mapboxgl-ctrl button:disabled .mapboxgl-ctrl-icon {
    opacity: 1 !important;
  }
}

.PopUpFooter {
  border: 1px solid rgba(0, 0, 0, 0.08);
  min-height: 76px;
  background-color: white;
  border-end-end-radius: 16px;
  border-end-start-radius: 16px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  margin: 0px 1px 0px 1px;
}
.PopUpFooterContent {
  float: right;
  margin-right: 40px;
  padding-top: 43px;
  min-height: 80px;
}
.SearchPopUpFooterContent {
  padding-top: 30px;
}
.PopupNavigator {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 40px;
  border-radius: 22px;
  top: 242px;
  position: absolute;
}
.next {
  padding: 6px 0px 0px 12px;
  right: 25px;
  z-index: 10;
}
.previous {
  padding: 6px 0px 0px 12px;
  left: 25px;
  z-index: 10;
}
.PopupNavigator :hover,
.PopupNavigator ::before,
.PopupNavigator ::after {
  cursor: pointer;
}
.modal-dialog.ZipPopUp.modal-sm {
  margin-left: 750px;
  margin-top: 34px;
}
p.fw-bold.text-wrap.mb-0.pb-0.tb-label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  font-family: 'Elevance-SemiBold';
}
p.fw-bold.text-wrap.mb-0.pb-0.tb-label img,
.rp-label img {
  margin-left: 5px;
}
p.text-wrap.tb-value {
  font-size: 16px;
  font-family: 'Elevance-Medium';
}
.RacePopup {
  border-radius: 8px;
  border: 1px solid #3333;
  padding: 9px;
  padding-right: 0;
  padding-left: 0;
}
.rp-label {
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
}
.RacePopup .list-group-item {
  border: 0 !important;
  padding: 0.01rem 1.25rem !important;
  padding: 0px 0 0px 15px;
  line-height: 15px;
}
.RacePopup .ListLabel {
  font-weight: bold;
  font-size: 14px;
  margin-left: 30px;
}
#SearchForm {
  border: 1px solid #3333 !important;
  border-left: 0 !important;
  padding: 0;
  margin-left: -2px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
#SearchForm img {
  padding: 0;
}
.SearchPopUp {
  margin-left: 681px !important;
  top: 61px;
}

.SearchPopUp .LabelTab {
  margin-top: 18px;
  background-color: #501384;
}
.SearchPopUp .form-control {
  border-right: 0 !important;
  border-radius: 8px 0px 0px 8px;
}
.MapPopUpHeader,
.SearchPopUpHeader {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 10%);
  padding: 17px 0 16px 20px;
}
.MapPopUpBody,
.SearchPopUpBody {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  min-height: 530px;
  border-bottom: 0;
}
.customCloseIcon {
  width: 25px;
  float: right;
}
.customCloseIcon:hover {
  cursor: pointer;
}
.SearchPopUpHeader {
  border-radius: 16px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  border-bottom: 0;
  padding: 16px 0 0px 0px;
  height: 64px;
}
.SearchPopUpBody {
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  margin-top: -2px;
  border-top: 0;
  min-height: 468px;
}
.MapPopUpBody,
.SearchPopUpBody {
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}
.SearchPopUp .LabelTab {
  margin: 0px -16px 0px -16px;
}

.MapPopup .nav {
  margin-bottom: 28px !important;
  width: 100%;
  padding-top: 6px !important;
}
.SearchPopUpFooterContent {
  position: absolute;
  right: 0;
}
.TypeHeadDropDownContent ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0 13px;
}

.TypeHeadDropDownContent li {
  padding: 10px 9px;
  cursor: pointer;
  margin-left: 0 !important;
}

.TypeHeadDropDownContent li:hover {
  background: lightgray;
  text-decoration: none;
}
.TypeHeadDropDownContent {
  width: 273px;
  padding: 16px 0px;
  z-index: 1;
  position: absolute;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 16px 32px 0px rgb(0 0 0 / 10%);
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 9px;
}
.MapPopup .MapPopUpHeader .customCloseIcon {
  margin: -19px 11px 0 0;
}
.customTable .raceList {
  line-height: 30px;
}
.customTable .ListLabels {
  padding-right: 11px;
}
.whiteBreadCrumb .breadcrumb {
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;
}
.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}
.input-group-append {
  margin-left: -2px;
}
.ZipPopUp .TypeAheadDropDown {
  width: 227px;
}
.sidenav-wrapper hr {
  display: none;
}
.state-marker-data,
.county-marker-data {
  background: rgb(206, 3, 3);
  border-radius: 8px;
  height: auto;
  width: 264px;
  color: #000000 !important;
  border: 1px solid #286ce2;
  position: absolute;
  text-align: center;
  margin-top: -100px;
  margin-left: 4px;
  padding: 10px 0;
}

.collapse-heading-panel {
  /* border-bottom: 1px solid; */
}
.irs--big {
  height: 90px !important;
}
.collapsediv {
  -webkit-box-shadow: 0px -2px 25px -6px rgba(184, 184, 184, 0.71);
  -moz-box-shadow: 0px -2px 25px -6px rgba(184, 184, 184, 0.71);
  box-shadow: 0px -2px 25px -6px rgba(184, 184, 184, 0.71);
  padding: 20px;
  border-radius: 10px;
}
.food-insecurtity > img,
.tb-label > img {
  cursor: help;
}
.mobile-filters li input[type="checkbox"] {
  margin-right: 15px;
}
.disable-customfilter,
.disable-customfilter > .collapse-heading-panel {
  background-color: #ccc;
  cursor: not-allowed;
  color: #959292;
}
.disable-customfilter {
  box-shadow: -1px 9px 24px 7px rgba(0, 0, 0, 0.1);
}
.red {
  color: red;
}
