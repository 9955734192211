.top-nav {
  background: linear-gradient(90deg, rgb(250, 250, 250) 0%, rgb(255, 255, 255) 100%);
}
.cstm-submit {
  font-family: "Elevance-Bold";
  font-size: 16px !important;
  background: #175bd1 !important;
  color: #fff !important;
  text-align: center !important;
  width: 170px;
  border-radius: 8px !important;
}
.modal-content {
  color: #fff;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  opacity: unset !important;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  background: #5009B5 !important;
  color: #fff !important;
  font-family: 'Elevance-Medium';
}
.bs-popover-bottom .arrow::after {
  border-bottom-color: #5009B5 !important;
}
.cstm-cancel {
  color: #175bd1 !important;
  text-align: center !important;
  width: 170px;
  border-radius: 8px !important;
  border: 2px solid rgb(240, 245, 255) !important;
  font-family: "Elevance-Bold";
  font-size: 16px !important;
}
.bg-blue {
  background: #5009B5 !important;
}
.cstm-dailog {
  max-width: 95% !important;
  margin: 0 auto !important;
}
@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scaleIn {
  0% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.fwc-anim-img-scaleIn {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fwc-anim-img-scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  width: 100%;
  height: auto;
}
.banner-heading {
  color: rgb(51, 51, 51);
  font-family: "Elevance-SemiBold";
  font-size: 38px;
}
.banner-paragraph {
  color: rgb(51, 51, 51);
  font-family: "Elevance-Regular";
  font-size: 16px;
  padding: 5px;
}
.banner-paragraph span {
  font-family: "Elevance-Bold";
  font-size: 19px;
}
.banner-heading-mobile {
  color: rgb(255, 255, 255);
  font-family: "Elevance-SemiBold";
  font-size: 30px;
}
.banner-paragraph-mobile {
  color: rgb(255, 255, 255);
  font-family: "Elevance-Regular";
  font-size: 16px;
}
.short-hero-section {
  padding: 138px 0px;
}
.carousel-item {
  position: relative;
}
.banner-text {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 7.8%;
  width: 100%;
}
.mobile-banner-text {
  position: absolute;
  bottom: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  color: #fff;
}
.think-logo {
  width: 89px;
  height: 42px;
}
.mobile-logo {
  display: none !important;
}
.mobile-banner {
  display: none !important;
}
.desktop-banner {
  display: block !important;
}
.desktop-logo {
  position: relative;
  left: 6%;
}
@media only screen and (max-width: 767px) and (min-width: 360px) {
  .desktop-logo {
    display: none !important;
  }
  .mobile-logo {
    display: block !important;
  }
  .desktop-banner {
    display: none !important;
  }
  .mobile-banner {
    display: block !important;
  }
  /*.mobile-banner img{*/
  /*  height: 800px !important;*/
  /*}*/
  .modalSurvey {
    background-image: none !important;
  }
}
@media only screen and (max-width: 1390px) and (min-width: 1130px) {
  .cstm-width {
    flex: 0 0 90% !important;
    max-width: 90% !important;
  }
}
@media only screen and (max-width: 1590px) and (min-width: 1391px) {
  .cstm-width {
    flex: 0 0 67% !important;
    max-width: 67% !important;
  }
}
@media only screen and (min-width: 1500px) {
  .banner-heading {
    font-size: 45px !important;
  }
  .banner-paragraph {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 1700px) {
  .banner-text {
    width: 90% !important;
  }
}
@media only screen and (min-width: 1800px) {
  .banner-text {
    width: 89% !important;
  }
  .banner-paragraph {
    font-size: 19px !important;
  }
}

.sq-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Elevance-Medium';
  font-weight: 600;
}

/* Hide the browser's default radio button */
.sq-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #286ce2;
}

/* On mouse-over, add a grey background color */
.sq-radio:hover input ~ .checkmark {
  background-color: #286ce2;
  /* padding: 10px; */
}

.sq-radio input:checked ~ .checkmark {
  /* background-color: #022C5D; */
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.sq-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.sq-radio .checkmark:after {
  top: 1.5px;
  left: 1.5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #286ce2;
}
.borderlessInput {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #2455f0;
  opacity: 1 !important;
  width: 45%;
  margin-top: -8px;
  margin-left: 15px;
}
.surveyForm {
  color: #82868b !important;
}
.surveyFormQuestion,
.toggle-switch {
  color: #286ce2 !important;
}

.surveyButtonArrow {
  /* font-size: 22px; */
}
.modalSurvey {
  /* background-image: url("../assets/images/backgroundSurvey.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 43%;
  background-position-y: bottom; */
  min-height: 750px;
}
.modal-header .container h3{
  font-family: "Elevance-Bold";
}
.modal-header .container p{
  font-family: 'Elevance-Regular';
}
.surveyFormQuestion p{
  font-family: 'Elevance-SemiBold';
}
